import {get,post} from "./api"
// ------------------首页-------------------
// 获取用户信息
export const getUinfo = params=>get("shop/Shop/shopInfo",params)
// 获取店铺订单数据集合
export const getDatas = params=>get("Shop/home/getShopData",params)
// 获取7天内数据
export const getAweekData = params=>get("Shop/home/getSmsData",params)
// 获取首页任务列表
export const getHomeTaskList = params=>get("Shop/home/getTaskList",params)
// 获取公告列表
export const getNotice = params=>get("shop/notice/index",params)
// 获取验证码
export const getCode = data =>post("shop/shop/sendShopMobile",data)
// 绑定手机号
export const bindMobile = params => get("shop/shop/checkCode",params)
// 获取短信剩余和使用数量
export const getSmsMsg = params=>get("Shop/home/getSmsNumber",params)
// 退出登录
export const getOut=params=>get("shop/shop/loginOut",params)
// 验证token是否过期
export const testToken = data =>post("shop/Login/isToken",data)
// 获取nps组件配置
export const getNpsConfig = params=>get("shop/home/getTokenEncryption",params)

//-------------------短信模板---------------------
// 获取模板分类
export const getTemplateType = params=>get("shop/Template/getTemplateCateList",params)
// 获取模板列表
export const getTemplateList = params=>get("shop/Template/getTemplateList",params)
// 创建自定义模板
export const createTemplate = data =>post("shop/Template/add",data)
// 生成短链接
export const createShortLink = data=>post("shop/Template/createUrl",data)

// ------------------会员----------------------
// 获取会员列表
export const getCustomerList=params=>get("shop/user/getlist",params)
// 筛选会员
export const screeningMembers = params=>get("shop/User/budgetUserTotal",params)
// 根据筛选会员发送短信
export const memberSengMsg = data=>post("shop/User/appointPinSend",data)
// 添加黑名单
export const addBlackList = data=>post("shop/User/addUserBlacklist",data)
// 获取黑名单列表
export const getBlackList = params=>get("shop/User/getUserBlacklist",params)

// ------------------任务-----------------------
// 修改任务状态
export const changeTask = data=>post("shop/Task/status",data)
// 获取短信发送记录
export const getSmsSend = params=>get("shop/Sms_log/getList",params)
// 获取修改任务信息
export const getModifyTaskMsg = params =>get("shop/Task/getInfo",params)
// 修改任务
export const ModifyTask = data=>post("shop/Task/save",data)
// 创建任务
export const createTask = data=>post("shop/Task/add",data)
// 删除任务
export const delTask = data=>post("shop/Task/del",data)
// 获取每个环节任务列表
export const getTaskList = params=>get("shop/Task/getList",params)
// 修改任务优先级
export const modifyTaskLevel = data=>post("shop/Task/sort",data)
// 获取商品列表
export const getGoodsList = params=>get("shop/Task/getGoodsSku",params)
// 催付效果分析
export const expeditingEffect = params=>get("shop/Sms_log/getAnalysisList",params)
// 测试发送
export const testsend = data=>post("shop/task/testSend",data)

// ------------------短信充值----------------------
// 获取充值金额分类
export const getPayType = params=>get("shop/pay/getPayList",params)
// 获取充值记录列表
export const getRechargeList = params=>get("shop/pay/getPayLogList",params)
// 发起支付请求
export const payRequest = data=>post("shop/pay/add",data)
// 获取支付结果
export const payResult = params =>get('shop/pay/checkIsPay',params)

//-------------------订单---------------------
// 获取订单列表
export const getOrderList = params=>get("shop/Order/getOrderList",params)
// 解码用户信息
export const decode = params=>get("shop/shop/ct",params)
// 提交短信发送任务
export const submitSendTask = data=>post("shop/order/appointNewOrderSend",data)
// 查询订单明细
export const queryOrderDetailed = params=>get("shop/order/getOrderIdsList",params)
// 获取指定订单的订单sku列表
export const getSkuList = params=>get("shop/order/getOrderDada",params)

// -------------------群发---------------------
// 导入订单群发
export const importOrder=data =>post("shop/order/importOrderSend",data)
// 导入号码群发
// export const mobileSend = data =>post("shop/order/appointMobileSend",data)
// 获取群发任务列表
export const getGroupSendList = params=>get("shop/Send/getList",params)
// 获取群发任务详情列表
export const getSendList = params=>get("shop/Send/getSendLogList",params)

// -----------------京东日志------------------
// 登录成功日志提交
export const getSuccess = data=>post("shop/Upload_Log/loginLog",data)
// 登录失败日志提交
export const getFail = data => post("shop/Upload_Log/loginLogError",data)
// 获取订单向京东提交日志
export const submitJournal = data =>post("shop/Upload_Log/orderLog",data)

// ---------------------人群-------------------
// 获取人群标签信息
export const getLabelMsg = params =>get("shop/Crowd/getShopLable",params)
// 获取省市
export const getProvinces = params=>get("shop/Crowd/getCity",params)
// 获取三级分类类目
export const getThreeLevel = params =>get("shop/Crowd/getCategory",params)
// 通过标签预估人群数
export const estimatePeople = data =>post("shop/Crowd/strategyCompute",data)
// 保存人群
export const holdCrowd = data =>post("shop/Crowd/strategyCreate",data)
// 获取人群列表
export const getCrowdList = params=>get("shop/Crowd/getList",params)
// 创建人群短信发送
export const crowdMsgSend = data=>post("shop/Crowd/smsNotify",data)
// 获取人群发送列表
export const getCrowdSendList = params=>get("shop/Crowd/getCrowdLog",params)
// 轮询修改发送状态
export const changeSentStatus = data=>post("shop/Crowd/pollingCrowdLog",data)
// 删除人群策略
export const delCrowd = data=>post("shop/Crowd/delCrowd",data)
// 更新人群数量
export const upDateCrowdNum = data=>post("shop/Crowd/refreshCrowdCount",data)
// 系统人群初始化创建
export const getSystemCrowd = data=>get("shop/Crowd/tsystemerStrategyCreate",data)

// -----------------------个人中心----------------------------
// 修改店铺签名
export const changeLabel = data=>post("shop/shop/updateSign",data)
// 短信签名修改记录
export const changeLabelRecord = params=>get("shop/shop/getSignLogList",params)
// 获取个人中心配置
export const getDispose = params=>get("shop/shop/getShopConfig",params)
// 保存软件到期和短信剩余提醒
export const remind = data=>post("shop/shop/getShopConfig",data)

// -----------------公告-----------------
// 获取公告详情
export const getDetails = params=>get("shop/notice/show",params)