import navlist from "../components/leftnav/leftnav.json"
import { testsend } from "../apis/index"
import { message } from "../assets/js/resetMessage"
let list = navlist.nav
// 处理json路由信息
export const getNav = function () {
  let routes = []
  for (const key in list) {
    for (let i = 0; i < list[key].child.length; i++) {
      // if (list[key].child[i].childs) {
      //   for (const k in list[key].child[i].childs) {
      //     let obj = list[key].child[i].childs[k]
      //     routes.push({
      //       path: obj.path,
      //       name: obj.name,
      //       meta: obj.meta,
      //       component: () => import(`../views${obj.path}.vue`)
      //     })
      //   }
      // } else {
        let obj = list[key].child[i]
        routes.push({
          path: obj.path,
          name: obj.name,
          meta: obj.meta,
          component: () => import(`../views${obj.path}.vue`)
        })
      // }
    }
  }
  return routes
}
// 防抖函数
export function debounce(fn, delay) {
  delay = delay || 100
  let timer
  return function () {
    let that = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(that, args)
    }, delay);
  }
}
// 节流函数
export function throttle(fn, interval) {
  let last
  let timer
  interval = interval || 100
  return function () {
    let that = this
    let args = arguments
    let now = +new Date()
    if (last && now - last < interval) {
      clearInterval(timer)
      timer = setInterval(() => {
        last = now
        fn.apply(that, args)
      }, interval);
    } else {
      last = now
      fn(that, args)
    }
  }
}
// 时间戳处理函数
export function getTimes(time) {
  let date = new Date(time * 1000)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = date.getDate()
  day = day < 10 ? `0${day}` : day
  let hours = date.getHours()
  hours = hours < 10 ? `0${hours}` : hours
  let minute = date.getMinutes()
  minute = minute < 10 ? `0${minute}` : minute
  let seconds = date.getSeconds()
  seconds = seconds < 10 ? `0${seconds}` : seconds
  return `${year}-${month}-${day} ${hours}:${minute}:${seconds}`
}
// 获取时分
export function gettime(time) {
  let date = new Date(time);
  let hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minute = date.getMinutes();
  minute = minute < 10 ? `0${minute}` : minute;
  return `${hours}:${minute}`;
}
// 获取年月日
export function getdate(time) {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
}
// 封装测试发送
export function testSend(content, tel) {
  if (content) {
    if (tel) {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg_tel.test(tel)) {
        let data = {
          mobile: tel,
          content: content
        }
        testsend(data).then(res => {
          message({
            message: res.msg,
            type: "success"
          })
        })
      } else {
        message({
          message: "请输入正确的手机号！",
          type: 'warning'
        })
      }
    } else {
      message({
        message: "手机号码不能为空！",
        type: 'warning'
      })
    }
  } else {
    message({
      message: "短信内容不能为空！",
      type: 'warning'
    })
  }
}