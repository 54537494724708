import Vue from 'vue'
import App from './App.vue'
// import myaxios from "./utils/myaxios"
import router from './router'
import store from './store'
import "./assets/iconfont/iconfont.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {message} from "./assets/js/resetMessage"
if(localStorage.getItem("uinfo")){
  store.commit("info",JSON.parse(localStorage.getItem("uinfo")))
}
Vue.config.productionTip = false
// Vue.prototype.$myaxios=myaxios
// 事件总线，实现兄弟组件传值
Vue.prototype.$bus=new Vue()
Vue.use(ElementUI)
Vue.prototype.$message=message
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
